import {
  BlockIcon,
  ChatIcon,
  FollowIcon,
  HandShakeSimpleIcon,
  CompanyIndustryIcon,
  JobsIcon,
  ReportIcon,
  ThreeDot,
  FollowingIconV2,
  UsersIcon,
  ChatOutLined,
  CalendarIcon,
  CallIcon,
  CallIconNew,
  CompanyIndustryIconNew,
  WebsiteIcon,
} from '@mybridge/icons';
import { Tooltip } from '@mybridge/ui/tooltip';

import { CompanyIcon } from '@mybridge/icons/Company';
import { CompanyLocationIcon } from '@mybridge/icons/CompanyLocation';
import { MeetingDefaultIcon } from '@mybridge/icons/NotificationIcons';
import {
  Avatar,
  Box,
  Button,
  HStack,
  Heading,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect } from 'react';
import {
  getUserFullName,
  getUserLocation,
  getUserProfilePic,
  formatLocation,
  formatCommaSeparated,
} from 'v4/lib/commons';
import { MyNetworkContext } from '../network/context';
import { MatrixContext } from 'packages/mybridgev4/context/chat-context';
import { useSelector, useDispatch } from 'react-redux';
import { UserPresenceDot } from '../user-presence-dot';
import {
  followCompanyPage,
  unFollowCompanyPage,
} from 'v4/store/actions/companyPages.actions';
import { getAllConnectionIDs } from 'v4/store/actions/connection.actions';
import { ForceSignInWrapper } from '../force-sign-in-wrapper';
import { startChatWithUser } from 'v4/store/actions/chat.actions';
import axios from 'axios';
import { getPublicProfile } from 'v4/store/actions/user-profile.actions';
import { UserCardSkeleton } from '../user-card-skeleton';
import { useRouter } from 'next/router';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const UserDetailsPopover = forwardRef(
  (
    {
      user,
      company,
      children,
      actionProps,
      showPresence = false,
      chatRoomId,
      ...props
    },
    ref
  ) => {
    const {
      isFriend,
      handshakeUser,
      followUser,
      isHandshakeRequested,
      isFollowed,
      handshakeLoading,
      followLoading,
      setSelectedUser,
      reportUserDisc,
      blockUserDisc,
      remindHandshake,
      unfollowUser,
      allConnectionIDs,
      isCompanyFollowed,
      handleHandshakeSendRevoke,
    } = useContext(MyNetworkContext);
    const { userProfileInfo } = useSelector((s) => s.userProfile);
    const { loading } = useSelector((s) => s.companyPage);
    const { startChatSingle, startMeetingSingle,initiateCall } = useContext(MatrixContext);
    const fullName = getUserFullName(user);
    const profilePic = getUserProfilePic(user);
    const { slug, matrix_chat_id } = user ?? {};
    // const { experiences } = user ?? {};
    // const { title: position, company_name } = experiences?.[0] ?? {};
    const { position,users_experiences,connect_count } = user ?? {};
    const company_name=users_experiences?.[0]?.company_name
    const location = getUserLocation(user);
    const dispatch = useDispatch();
    const { t } = useTranslator();
const router=useRouter()
    // useEffect(() => {
    //   if (user?.slug) {
    //     dispatch(getPublicProfile(user.slug));
    //   }
    //   console.log("User changed:", user);
    // }, [user, dispatch]);
    
    const {
      selectedChat,
       setSelectedChat,
       connectionList,
       setConnectionList,
       roomId, 
       setRoomId,
       newChatDisc
    } = useContext(MatrixContext);
console.log(startMeetingSingle,"setRoomIdsetRoomId","!UHDJWdvKQqGVcFnIOp:chat.mybridge.me");
    return (
      <Popover
        // isOpen={true}
        placement="top"
        trigger="hover"
        zIndex={99999}
        openDelay={500}
        {...props}
      >
        <PopoverTrigger boxShadow={'1px 2px 6px 0px #00000033'} zIndex={99999}>

          {children}
        </PopoverTrigger>
        <Portal>
    

          {company ? (
            <>
              <PopoverContent boxShadow={'1px 2px 6px 0px #00000033'}>
                {/* <PopoverAnchor/> */}

                <PopoverArrow />
                <PopoverBody p={4}>
                  <HStack alignItems="flex-start">
                    <HStack pr={4}>
                      <UserPresenceDot
                        user={user}
                        w="4"
                        h="4"
                        right="1"
                        bottom="1"
                        display={showPresence ? 'block' : 'none'}
                      >
                        <Avatar
                          {...(company?.slug?.length
                            ? { as: Link, href: '/c/' + company?.slug }
                            : {})}
                          name={company?.name}
                          src={company?.image}
                          size="lg"
                          loading="lazy"
                        />
                      </UserPresenceDot>
                    </HStack>
                    <Stack gap={0} pt={2}>
                      <Heading
                        {...(company?.slug?.length
                          ? { as: Link, href: '/c/' + company?.slug }
                          : {})}
                        size="sm"
                      >
                        {company?.name}
                      </Heading>

                      <Text size="sm">{company?.company_type}</Text>
                    </Stack>
                  </HStack>
                  <Stack mt={4} mb={4} spacing={2}>
                    {company?.country?.length ? (
                      <HStack alignItems="flex-start">
                        <Box flexShrink={0}>
                          <CompanyLocationIcon
                            color="#9F9F9F"
                            width={'17'}
                            height={'17'}
                            style={{ marginTop: '2px' }}
                          />
                        </Box>
                        <Text size="sm">{formatLocation(company)}</Text>
                      </HStack>
                    ) : (
                      ''
                    )}
                    {company?.main_industry ? (
                      <HStack alignItems="flex-start">
                        <Box flexShrink={0}>
                          <CompanyIndustryIconNew
                            color="#9F9F9F"
                            width={'17'}
                            height="17"
                            style={{ marginTop: '2px' }}
                          />
                        </Box>
                        <Text size="sm">{company?.main_industry}</Text>
                      </HStack>
                    ) : (
                      ''
                    )}

                    {company?.website ? (
                      <HStack alignItems="flex-start">
                        <Box flexShrink={0}>
                          <WebsiteIcon
                            color="#9F9F9F"
                            width={'17'}
                            height="17"
                            style={{ marginTop: '2px' }}
                          />
                        </Box>
                        <Text size="sm">{company?.website}</Text>
                      </HStack>
                    ) : (
                      ''
                    )}
                    {company?.follower_count ? (
                      <HStack alignItems="flex-start">
                        <Box flexShrink={0}>
                          <FollowIcon
                            color="#9F9F9F"
                            width={'17'}
                            height="17"
                            style={{ marginTop: '2px' }}
                          />
                        </Box>
                        <Text size="sm">{company?.follower_count}</Text>
                      </HStack>
                    ) : (
                      ''
                    )}
                  </Stack>
                  <Stack>
                    <ForceSignInWrapper>
                      {/* <PopoverFooter p={4}> */}

                      <HStack>
                        {isCompanyFollowed?.(company) ? (
                          <Box
                            w={'41px'}
                            height={'41px'}
                            border={'1px solid #00000033'}
                            cursor="pointer"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={'50%'}
                            onClick={(e) => {
                              dispatch(unFollowCompanyPage(company?.id));
                              dispatch(getAllConnectionIDs());
                            }}
                          >
                            {/* <IconButton
_hover={{ bg: '#3D5A80' }}
                                onClick={(e) => {
                                  dispatch(unFollowCompanyPage(company?.id));
                                  dispatch(getAllConnectionIDs());
                                }}
                                isLoading={loading}
                                // isDisabled={isFollowed?.(user)}
                                // variant="primary"
                                icon={
                                  <FollowingIconV2 width="20" height="20" />
                                }
                                w="70px"
                              /> */}

                            <FollowingIconV2 width="18" height="18" />
                          </Box>
                        ) : (
                          <Box
                            w={'41px'}
                            height={'41px'}
                            border={'1px solid #00000033'}
                            cursor="pointer"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={'50%'}
                            onClick={(e) => {
                              dispatch(followCompanyPage(company?.id));
                              dispatch(getAllConnectionIDs());
                            }}
                          >
                            {/* <IconButton
_hover={{ bg: '#3D5A80' }}
                            onClick={(e) => {
                              dispatch(followCompanyPage(company?.id));
                              dispatch(getAllConnectionIDs());
                            }}
                            isLoading={loading}
                            // isDisabled={isFollowed?.(user)}
                            // variant="primary"
                            icon={<FollowIcon width="20" height="20" />}
                            w="70px"
                          /> */}

                            <FollowIcon width="18" height="18" />
                          </Box>
                        )}

                        <Menu>
                          <MenuButton
                            w={'41px'}
                            height={'41px'}
                            border={'1px solid #00000033'}
                            cursor="pointer"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius={'50%'}
                            variant="ghost"
                            as={IconButton}
                          >
                            <HStack justifyContent="center">
                              <ThreeDot width="20" height="20" />
                            </HStack>
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={(e) => {
                                setSelectedUser?.(user);
                                reportUserDisc?.onOpen?.();
                              }}
                              icon={<ReportIcon width="20" height="20" />}
                            >
                              {t('profilePhotosVideos.report') || "Report"}
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                setSelectedUser?.(user);
                                blockUserDisc?.onOpen?.();
                              }}
                              icon={<BlockIcon width="20" height="20" />}
                            >
                              {t('profileNetwork.block') || "Block"}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </HStack>
                      {/* </PopoverFooter> */}
                    </ForceSignInWrapper>
                  </Stack>
                </PopoverBody>
              </PopoverContent>
            </>
          ) : (
            <>
              {userProfileInfo?.id === user?.id ? (
                <></>
              ) : (
                <PopoverContent zIndex={99999} shadow="xl">
                  {/* <PopoverAnchor/> */}
                  
                  <PopoverArrow />
                  <PopoverBody zIndex={99999} p={4}>
                      {!fullName&&(
                        <Stack>

                          <UserCardSkeleton/>
                        </Stack>
                      )}
                    <HStack zIndex={99999} alignItems="flex-start">
                      <HStack zIndex={99999} pr={4}>

                        <UserPresenceDot
                          user={user}
                          w="4"
                          h="4"
                          right="1"
                          bottom="1"
                          display={showPresence ? 'block' : 'none'}
                          zIndex={99999}
                          >
                          {fullName&&(<Avatar
                            {...(slug?.length
                              ? { as: Link, href: '/p/' + slug }
                              : {})}
                            name={fullName}
                            src={profilePic}
                            size="lg"
                          />)}
                        </UserPresenceDot>
                      </HStack>
                      <Stack gap={0} pt={2}>
                        <Heading
                          {...(slug?.length
                            ? { as: Link, href: '/p/' + slug }
                            : {})}
                          fontSize="24px"
                          color={'#2E2E2A'}
                        >
                          {fullName}
                        </Heading>
                        {/* <Stack> */}
                        {position?.length ? (
                          <HStack alignItems="flex-start">
                            <Text size="sm">{position}</Text>
                          </HStack>
                        ) : (
                          ''
                        )}
                        {/* </Stack> */}
                      </Stack>
                    </HStack>
                    <Stack mt={4}>
                      {company_name?.length ? (
                        <HStack alignItems="flex-start">
                          <Box flexShrink={0}>
                            <CompanyIcon
                              color="#9F9F9F"
                              width={'16'}
                              height="16"
                              style={{ marginTop: '2px' }}
                            />
                          </Box>
                          <Text size="sm">{company_name}</Text>
                        </HStack>
                      ) : (
                        ''
                      )}
                      {location?.length ? (
                        <HStack alignItems="flex-start">
                          <Box flexShrink={0}>
                            <CompanyLocationIcon
                              color="#9F9F9F"
                              width={'16'}
                              height="16"
                              style={{ marginTop: '2px' }}
                            />
                          </Box>
                          <Text size="sm">{location}</Text>
                        </HStack>
                      ) : (
                        ''
                      )}

                      {connect_count ? (
                        <HStack alignItems="flex-start">
                          <Box flexShrink={0}>
                            <UsersIcon
                              color="#9F9F9F"
                              width={'16'}
                              height="16"
                              style={{ marginTop: '2px' }}
                            />
                          </Box>
                          <Text size="sm">{connect_count}</Text>
                        </HStack>
                      ) : (
                        ''
                      )}

                      <Stack>
                        <ForceSignInWrapper>
                          {/* <PopoverFooter p={4}> */}
                          {isFriend?.(user) ? (
                            <HStack>
                              {/* <IconButton
_hover={{ bg: '#3D5A80' }}
                                // onClick={(e) => startChatSingle?.(user)}
                                onClick={(e) => {
                                  chatRoomId
                                    ? dispatch(startChatWithUser(chatRoomId))
                                    : startChatSingle?.(user);
                                }}
                                icon={<ChatIcon width="20" height="20" />}
                                w="70px"
                              /> */}

                              <Box
                                w={'41px'}
                                height={'41px'}
                                border={'1px solid #00000033'}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius={'50%'}
                                onClick={(e) => {
                                  router.push(`/profile/message?user=${user?.matrix_user_id}`)
                                }}
                                _hover={{ bg: 'gray.100' }}
                              >
                                <ChatOutLined
                                />
                              </Box>

                              <Box
                                w={'41px'}
                                height={'41px'}
                                border={'1px solid #00000033'}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius={'50%'}
                                onClick={(e) => startMeetingSingle?.(user)}
                                _hover={{ bg: 'gray.100' }}
                              >
                                <MeetingDefaultIcon
                                  height={'18px'}
                                  width={'18px'}
                                />
                              </Box>

                              <Box
                                w={'41px'}
                                height={'41px'}
                                border={'1px solid #00000033'}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius={'50%'}
                                onClick={()=>{
                                  router.push(`/profile/calendar/schedule?id=${user?.id}`)
                                }}
                                _hover={{ bg: 'gray.100' }}
                              >
                                <CalendarIcon
                                  height={'18px'}
                                  width={'18px'}
                                  // onClick={(e) => startMeetingSingle?.(user)}
                                />
                              </Box>

                              <Box
                                w={'41px'}
                                height={'41px'}
                                border={'1px solid #00000033'}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius={'50%'}
                                _hover={{ bg: 'gray.100' }}
                                onClick={(e) => {
                                  initiateCall(user?.matrix_user_id,'audio')
                                }}
                              >
                                <CallIconNew
                                  height={'18px'}
                                  width={'18px'}
                                  // onClick={(e) => startMeetingSingle?.(user)}
                                />
                              </Box>

                              {/* <IconButton
_hover={{ bg: '#3D5A80' }}
                                onClick={(e) => startMeetingSingle?.(user)}
                                icon={
                                  <Tooltip variant="round" label="Add Gif">
                                    <MeetingDefaultIcon
                                      width="20"
                                      height="20"
                                    />
                                  </Tooltip>
                                }
                                w="70px"
                              /> */}
                              <Menu>
                                <MenuButton
                                  w={'41px'}
                                  height={'41px'}
                                  border={'1px solid #00000033'}
                                  cursor="pointer"
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  borderRadius={'50%'}
                                  variant="none"
                                  as={IconButton}
                                  _hover={{ bg: 'gray.100' }}
                                >
                                  <HStack justifyContent="center">
                                    <ThreeDot width="20" height="20" />
                                  </HStack>
                                </MenuButton>
                                <MenuList>
                                  <MenuItem
                                    onClick={(e) => {
                                      setSelectedUser?.(user);
                                      reportUserDisc?.onOpen?.();
                                    }}
                                    icon={<ReportIcon width="20" height="20" />}
                                  >
                                    {t('reportBlock.reportUser') || "Report User"}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) => {
                                      setSelectedUser?.(user);
                                      blockUserDisc?.onOpen?.();
                                    }}
                                    icon={<BlockIcon width="20" height="20" />}
                                  >
                                    {t('reportBlock.blockUser') || "Block User"}
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </HStack>
                          ) : (
                            <HStack>
                              {isHandshakeRequested?.(user) ? (
                                <Tooltip label="Withdraw Handshake">
                                  <IconButton
                                    onClick={(e) =>
                                      handleHandshakeSendRevoke?.(user)
                                    }
                                    _hover={{ bg: 'gray.100' }}
                                    isLoading={handshakeLoading}
                                    // isDisabled={isHandshakeRequested?.(user)}
                                    
                                    icon={
                                      <HandShakeSimpleIcon
                                        color="rgb(179, 140, 140)"
                                        width="20"
                                        height="20"
                                      />
                                    }
                                    w={'41px'}
                                    height={'41px'}
                                    border={'1px solid #00000033'}
                                    cursor="pointer"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius={'50%'}
                                    
                                    variant="none"
                                  />

                                </Tooltip>
                              ) : (
                                <Tooltip zIndex="99999999" label="Send Handshake Request">
                                  <IconButton
                                    w={'41px'}
                                   _hover={{ bg: 'gray.100' }}
                                    height={'41px'}
                                    border={'1px solid #00000033'}
                                    cursor="pointer"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius={'50%'}
                                    variant="none"
                                    onClick={(e) => handshakeUser?.(user)}
                                    isLoading={handshakeLoading}
                                    // w="70px"
                                    // isDisabled={isHandshakeRequested?.(user)}
                                    icon={
                                      <HandShakeSimpleIcon
                                        color="rgb(148, 176, 150)"
                                        width="20"
                                        height="20"
                                      />
                                    }
                                  />
                                </Tooltip>
                              )}

                              {/* {isFollowed?.(user) ? (
                                 <IconButton
_hover={{ bg: '#3D5A80' }}
                                   onClick={(e) => unfollowUser?.(user)}
                                   isLoading={followLoading}
                                   // isDisabled={isFollowed?.(user)}
                                   // variant="primary"
                                   icon={
                                     <FollowingIconV2
                                       // color="white"
                                       width="20"
                                       height="20"
                                     />
                                   }
                                   w="70px"
                                   padding={0}
                                 />
                               ) : (
                                 <IconButton
_hover={{ bg: '#3D5A80' }}
                                   onClick={(e) => followUser?.(user)}
                                   isLoading={followLoading}
                                   // isDisabled={isFollowed?.(user)}
                                   // variant="primary"
                                   icon={
                                     <FollowIcon
                                       // color="white"
                                       width="20"
                                       height="20"
                                     />
                                   }
                                   w="70px"
                                 />
                               )} */}

                              {/* <IconButton
_hover={{ bg: '#3D5A80' }}>
                   <ThreeDot width="20" height="20" />
                 </IconButton> */}
                              <Menu>
                                <MenuButton 
                                _hover={{ bg: 'gray.100' }}
                                w={'41px'}
                                height={'41px'}
                                border={'1px solid #00000033'}
                                cursor="pointer"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                borderRadius={'50%'}
                                variant="none"
                                as={IconButton}>
                                  <HStack justifyContent="center">
                                    <ThreeDot width="20" height="20" />
                                  </HStack>
                                </MenuButton>
                                <MenuList>
                                  <MenuItem
                                    onClick={(e) => {
                                      setSelectedUser?.(user);
                                      reportUserDisc?.onOpen?.();
                                    }}
                                    icon={<ReportIcon width="20" height="20" />}
                                  >
                                    {t('profilePhotosVideos.report') || "Report"}
                                  </MenuItem>
                                  <MenuItem
                                    onClick={(e) => {
                                      setSelectedUser?.(user);
                                      blockUserDisc?.onOpen?.();
                                    }}
                                    icon={<BlockIcon width="20" height="20" />}
                                  >
                                    {t('profileNetwork.block') || "Block"}
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </HStack>
                          )}

                          {/* </PopoverFooter> */}
                        </ForceSignInWrapper>
                      </Stack>
                    </Stack>
                  </PopoverBody>
                </PopoverContent>
              )}
            </>
          )}
        </Portal>
      </Popover>
    );
  }
);
